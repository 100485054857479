* {
  margin: 0;
  padding: 0;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


:root {
  --color-primary: #a5005d;
  --color-secondary: #1d2025;
  --font-primary: "Montserrat", Helvetica, Arial, sans-serif;
}

*::selection {
  background-color: var(--color-primary);
  color: #fff !important;
}


*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-family: "Montserrat", sans-serif;
  // font-size: 62.5% !important; // 10px = 10px, 10px/16px = 62.5%
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  scroll-behavior: smooth;
  line-height: 1.5;
  color: #545454;
  width: 100%;
  overflow-x: hidden;
}

body {
  font-family: 'Montserrat', sans-serif;
  background-repeat: no-repeat;
  min-height: 100vh;
  max-width: 100%;
  background-color: #fff !important;
  overflow-x: hidden !important;
  font-size: 14px;
  // background-color: #F5F7FA !important;
}

.bg-sec-link{
  background: var(--color-secondary) !important;

  &:hover{
    transition: .2s all ease-in-out;
    background: var(--color-primary) !important;
    color: #fff !important;
    cursor: pointer;
  }
}



// .navbar-active {
//   position: fixed !important;
//   width: 100%;
//   max-width: 100%;
//   box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12);
//   border: 0 !important;
//   top: 0 !important;
//   left: 0 !important;
//   transition: .2s;
//   z-index: 1000 !important;
//}

.navbar-active .Navbar_main {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.navbar-active .Navbar_nav-collapse{

  @media only screen and (max-width: 990px) {
    background-color: #fff !important;
  }
}

.modal-active {
  top: 10% !important;
}

.navbar-active {
  position: fixed !important;
  width: 100%;
  max-width: 100%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12);
  border: 0 !important;
  top: 0 !important;
  left: 0 !important;
  transition: .2s;
  z-index: 1000 !important;
  color: #000 !important;
  background-color: #fff !important;
}

.navbar-active .Navbar_main {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.navbar-active .Navbar_nav-link {
  color: #000 !important;
}

.navbar-active .Navbar_nav-link2 {
  color: #000 !important;
}

// .navbar-active .Navbar_nav-btn {
//   color: #000 !important;
//   border-color: #000;
// }

.navbar-active .Navbar_nav-btn2 {
  color: #000 !important;
  border-color: #000
}



.modal-active {
  top: 10% !important;
}

.Navbar_main{
  background: linear-gradient(to bottom, rgba(0, 0, 0, .9) 9%, transparent 100%);
  padding: 10px 0px;
}


// .Navbar {
//   background: transparent;
//   z-index: 10000 !important;
//   width: 100%;
//   max-width: 100%;
//   transition: .2s all ease-in-out;
//   padding: 10px 14rem !important;
//   position: absolute;
//   top: 0;
//   left: 0;

//   @media only screen and (max-width: 990px) {
//     padding: 20px 30px !important;
//   }

//   @media only screen and (max-width: 768px) {
//     padding: 20px 0 !important;
//   }

//   &_image {
//     width: 100%;

//     @media only screen and (max-width: 1400px) {
//       margin-right: 0 !important;
//     }

//     @media only screen and (max-width: 990px) {
//       width: auto !important;
//     }

//     &-img {
//       height: auto;
//       width: 100%;
//       max-width: 100%;
//       object-fit: cover;


//       @media only screen and (max-width: 1400px) {
//         width: auto;
//       }
//     }
//   }


//   &_toggler {
//     color: #FF7F76 !important;
//     font-family: 'Font Awesome\ 5 Free';

//     &-icon {
//       font-family: 'Font Awesome\ 5 Free';
//       font-size: 30px;
//     }
//   }

//   &_nav {
//     display: flex !important;
//     transition: all .5s fade-in;
//     padding: 15px 0 !important;

//     @media only screen and (max-width: 990px) {
//       padding: 20px 30px !important;
//     }

//     &-collapse{
//       @media only screen and (max-width: 990px) {
//         background: #FDF4F3 !important;
//       }
//     }


//     &-images{
//       margin-right: 10px;

//       &--img1{
//         padding-right: 10px;
//       }

//       &--img2{
//         width: 50px;
//         height: auto;
//       }

//       &--img3{
//         width: 140px;
//         margin-top: 10px;
//       }
//     }


//     &-item {
//       display: flex;
//       flex-direction: column;
//       justify-content: center;
//       align-items: center;
//       margin: 0 20px;

//       @media only screen and (max-width: 1200px) {
//         margin-right: 2px !important;
//       }

//       @media only screen and (max-width: 990px) {
//         justify-content: flex-start !important;
//         align-items: flex-start !important;
//         text-align: left;
//         margin: 5px 0;
//       }
//     }

//     &-link {
//       color: #000;
//       font-style: normal;
//       font-weight: 500;
//       font-size: 16px;
//       font-family: "Inter", sans-serif;
//       text-transform: none;
//       white-space: nowrap;

//       @media only screen and (max-width: 1200px) {
//         font-size: 16px;
//       }

//       @media only screen and (max-width: 990px) {
//         width: 100%;
//         align-items: flex-start !important;
//       }

//       &:hover {
//         width: auto !important;
//         color: #FF7F76 !important;
//         text-decoration: none;
//         cursor: pointer;
//       }
//     }


//     &-btn {
//       font-size: 14px;
//       font-weight: 400;
//       color: #fff !important;
//       font-family: 'Montserrat', sans-serif;
//       padding: 10px 20px !important;
//       background-color: #a5005d;
//       border-radius: 20px;
//       border: none;
//       font-weight: 700;
//       margin-left: 100% !important;
//       white-space: nowrap;
//       text-transform: none !important;

//       &:hover {
//         background-color: var(--color-secondary) !important;
//         color: #fff;
//         text-decoration: none;
//         transition: all .5s;
//         cursor: pointer;
//       }
//     }

//   }
// }

.navbar-toggler{
  float: right !important;
}

.to-hide-1400{

  @media only screen and (max-width: 1400px) {
    display: none !important;
  }
}

.to-flex-1400{

  @media only screen and (max-width: 1400px) {
    display: flex !important;
    justify-content: center;
    margin: 0 auto;
  }
}


.navbar-active {
  position: fixed !important;
  width: 100%;
  max-width: 100%;
  background-color: #fff !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12);
  border: 0 !important;
  top: 0 !important;
  left: 0 !important;
  transition: 0.2s;
  z-index: 1000 !important;
}

.navbar-active .navbar_image-img {

  @media only screen and (max-width: 1200px) {
    height: 90px;
  }

  @media only screen and (max-width: 768px) {
    height: 60px;
  }

  @media only screen and (max-width: 480px) {
    height: 50px;
  }
}

.before-white{

  &::before{
    color: #fff !important;
  }
}

.Navbar_home {
  padding: 20px 0;

  @media only screen and (max-width: 990px) {
    padding: 0;
  }
}

.navbar_home-active {
  position: fixed !important;
  width: 100%;
  max-width: 100%;
  background-color: #fff !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12);
  border: 0 !important;
  top: 0 !important;
  left: 0 !important;
  transition: 0.2s;
  z-index: 1000 !important;
}

.m-l-10{
  margin-left: 10px;

  @media only screen and (max-width: 990px) {
    margin-left: 120px;
  }

  @media only screen and (max-width: 600px) {
    display: none !important;
  }
}

.navbar-active .Navbar_main{
  background: #fff !important;
}


.Navbar {
  background: transparent;
  z-index: 10000 !important;
  width: 100%;
  max-width: 100%;
  transition: .2s all ease-in-out;
  padding: 20px 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  // @media only screen and (max-width: 990px) {
  //   padding: 20px 30px !important;
  // }

  // @media only screen and (max-width: 768px) {
  //   padding: 20px 0 !important;
  // }

  &_image {
    margin-left: -15px !important;
    margin-top: 6px !important;

    @media only screen and (max-width: 1400px) {
      margin-right: 0 !important;
    }

    @media only screen and (max-width: 990px) {
      width: auto !important;
      margin-left: 0 !important;
    }

    &-img {
      height: 150px;
      width: auto;
      object-fit: contain;

      @media only screen and (max-width: 1400px) {
        width: auto;
      }

      @media only screen and (max-width: 768px) {
        height: 80px;
      }
    }
  }

  &_toggler {
    color: var(--color-primary) !important;
    font-family: 'Font Awesome\ 5 Free';

    &-icon {
      font-family: 'Font Awesome\ 5 Free';
      font-size: 30px;
    }
  }

  &_nav {
    display: flex !important;
    transition: all .5s fade-in;
    padding-top: 40px;

    @media only screen and (max-width: 990px) {
      padding: 20px 30px !important;
    }
    &-collapse{
      @media only screen and (max-width: 990px) {
        background: #000 !important;
      }
    }

    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 5px 12px;

      @media only screen and (max-width: 1200px) {
        margin-right: 2px !important;
      }

      @media only screen and (max-width: 990px) {
        justify-content: flex-start !important;
        align-items: flex-start !important;
        text-align: left;
        margin: 5px 0;
      }
    }

    &-link {
      font-family: 'Montserrat', sans-serif;
      color: #fff !important;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      text-transform: none;
      white-space: nowrap;

      &:hover {
        transition: .2s all ease-in-out;
        color: var(--color-primary) !important;
        text-decoration: none;
        cursor: pointer;
      }
    }

    &-btn {
      font-size: 14px;
      font-weight: 400;
      color: #fff !important;
      font-family: 'Montserrat', sans-serif;
      padding: 8px 20px !important;
      background-color: #a5005d;
      border-radius: 20px;
      border: none;
      font-weight: 700;
      margin-left: 100% !important;
      white-space: nowrap;
      text-transform: none !important;

      @media only screen and (max-width: 1400px) {
        margin-left: 0 !important;
      }

      &:hover {
        opacity: 0.8;
        color: #fff;
        text-decoration: none;
        transition: all .3s;
        cursor: pointer;
      }
    }
  }
}


.to-between{
  justify-content: space-between !important;

  @media only screen and (max-width: 990px) {
    justify-content: space-between !important;
  }
}

.slider {
  position: relative;
  background-image: url("../img/slide1.jpg");
  background-position: center;
  background-size: cover;
  padding: 0px 0px 43px;
  z-index: 0;

  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: linear-gradient(to bottom, rgba(0, 0, 0, .8) 9%, transparent 40%);
  //   z-index: -1;
  //   transition: 0.5s;
  // }

  &_content {
    position: relative;
    padding-top: 240px;
    padding-bottom: 100px;
    z-index: 100 !important;

    &-label {
      color: #000;
      font-weight: 600;
      font-family: "Montserrat", sans-serif;
      font-size: 18px;
    }

    &-title {
      font-family: 'Montserrat', sans-serif;
      color: #fff;
      font-weight: 700;
      text-align: center;
      font-size:  55px;
      background-color: rgba(0, 0, 0, 0.6);
      border-radius: 40px;
      margin-top: 55px;

      @media only screen and (max-width: 990px) {
        font-size: 45px;
      }

      @media only screen and (max-width: 768px) {
       font-size: 40px;
      }
    }

    &-p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 1.8;
      font-family: "Montserrat", sans-serif;
      color: #fff;
    }
  }

  &_form {
    padding: 20px;
    padding-bottom: 40px !important;
    margin-top: -20px;
    background-color: rgba(255,255,255,0.5);
    border-radius: 5px;

    @media only screen and (max-width: 990px) {
      margin-top: 0;
    }

    &-form{
      margin-top: 10px;
    }

    &-title{
      font-family: "Montserrat", sans-serif;
      color: #0a103c;
      font-size: 25px;
      font-weight: 700;
      text-align: center;
    }

    &-label {
      display: flex;
      justify-content: center;
      margin: 5px auto;
      font-family: "Montserrat", sans-serif;
      font-size: 16px;
      color: #696c7f;
      text-align: center;
      font-weight: 400;
    }

    &-input {
      padding: 7px 10px;
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.7;
      color: #000000;
      background: #FFFFFF;
      border: 1px solid #ccc;
      border-radius: 5px;
      width: 100%;
      max-width: 100%;
      outline: none;
      height: 4.20px;
    }

    &-textarea {
      padding: 10px;
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 1.7;
      color: #000000;
      background: #FFFFFF;
      border-radius: 5px;
      width: 100%;
      max-width: 100%;
      height: 100%;
    }

    &-btn {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      font-family: "Montserrat", sans-serif;
      border-radius: 5px 5px 5px 5px;
      background: var(--color-primary);
      border: .20px none transparent;
      box-shadow: 0px 0px 0px -7px rgba(0, 0, 0, 0);
      padding: 17px 15px !important;
      white-space: nowrap;
      color: #fff !important;
      text-transform: none !important;
      border-radius: 5px !important;

      @media only screen and (max-width: 1200px) {
        margin-right: 0 !important;
      }

      &:hover {
        background: var(--color-secondary) !important;
        color: #fff !important;
        transition: all .5s;
        cursor: pointer;
      }
    }
  }
}

.f-w-700{
  font-weight: 700 !important;
}

.bg-prim{
  background-color: var(--color-primary);
}

.p-y-5{
  padding: 20px 0 !important;
}

.no-wrap{
  white-space: nowrap !important;

  @media only screen and (max-width: 1300px) {
    white-space: pre-wrap !important;
  }
}

.align-to-center-990{

  @media only screen and (max-width: 990px) {
    align-items: center !important;
  }
}

.to-show-990{
  display: none !important;

  @media only screen and (max-width: 990px) {
    display: block !important;
  }
}

.to-hide-990{

  @media only screen and (max-width: 990px) {
    display: none !important;
  }
}

.rge {
  position: absolute;
  right: 15px;
  top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @media only screen and (max-width: 1200px) {
    display: none;
  }
}

.rge .line1 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.rge .logo-certif {
  width: 50px;
  height: auto;
}

.rge .logo-certif2 {
  width: 140px;
}



.row {
 

  .col-md-3,
  .col-sm-6 {
   

    .box5 {
      position: relative;
      overflow: hidden;

      .box5_img {
        margin: 7px;
        img {
          display: block;
          width: 100%;
          height: auto;
          transition: transform .4s cubic-bezier(.4,0,.2,1), opacity .4s, -webkit-transform .4s cubic-bezier(.4,0,.2,1);
        }

        .hover-overlay {
          position: absolute;
          top: 20px;
          left: 20px;
          bottom: 10px;
          right: 0;
          width: 88%;
          height: 88%;
          background: rgba(165, 0, 93, 0.7);
          opacity: 0;
          visibility: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          transform: scale(0.5); /* Initial scaling down */
          transition: transform 0.3s, opacity 0.3s, visibility 0.3s; /* Smooth transition */

          @media only screen and (max-width: 990px) {
            width: 95%;
            height: 95%;
            top: 0;
            left: 0;
          }

          .overlay-content {
            text-align: center;
            color: #fff;

            .icon3 {
              width: 50px;
              height: 50px;
              border: 1px solid rgba(255, 255, 255, .7);
              border-radius: 100%;
              line-height: 48px;
              color: #fff;
              display: inline-block;
              margin: 15px 5px;
              text-align: center;
              text-decoration: none;

              &:hover {
                color: #ddd;
              }

              i {
                display: inline-block;
                transition: transform 0.3s;
              }

              &:hover i {
                transform: scale(1.2);
              }
            }

            h4 {
              font-family: "Montserrat", sans-serif;
              margin-top: 10px;
              font-size: 16px;

              @media only screen and (max-width: 990px) {
                font-size: 12px;
                margin-top: 5px;
              }
            }
          }
        }

        &:hover img {
          // transform: scale(1.1);
        }

        &:hover .hover-overlay {
          opacity: 1;
          visibility: visible;
          transform: scale(1);
        }
      }
    }
  }
}

.box6 {
  width: 100%;
  height: 100px;
}

section.pre-contact .icon {
  border: .10px dashed #ccc;
  width: 80px;
  height: 80px;
  line-height: 80px;
  float: left;
  margin-right: 20px;

  border-radius: 50%;
  margin-bottom: 20px;
  display: inline-block;
  transition: all .3s ease;
  text-align: center;
  font-size: 30px;
  color: #a5005d;
}



.section {
  font-family: 'Montserrat', sans-serif;
  padding: 70px 210px;

  @media only screen and (min-width: 2200px) {
    padding: 70px 0;
  }

  @media only screen and (max-width: 1800px) {
    padding: 100px 70px;
  }


  @media only screen and (max-width: 1600px) {
    padding: 70px;
  }


  @media only screen and (max-width: 1200px) {
    padding: 50px 20px;
  }

  @media only screen and (max-width: 1100px) {
    padding: 50px 5px;
  }

  @media only screen and (max-width: 1000px) {
    padding: 50px 0;
  }

  @media only screen and (max-width: 990px) {
    padding: 50px 20px;
  }

  @media only screen and (max-width: 768px) {
    padding: 50px 0rem;
  }
}

.section-y {
  font-family: 'Montserrat', sans-serif;
  padding: 50px 0;
  font-size: 14px;

  @media only screen and (min-width: 2200px) {
    padding: 50px 0;
  }

  @media only screen and (max-width: 1800px) {
    padding: 50px 0;
  }


  @media only screen and (max-width: 1600px) {
    padding: 50px 0;
  }


  @media only screen and (max-width: 1200px) {
    padding: 50px 0;
  }

  @media only screen and (max-width: 1100px) {
    padding: 50px 0;
  }

  @media only screen and (max-width: 1000px) {
    padding: 50px 0;
  }

  @media only screen and (max-width: 990px) {
    padding: 50px 0;
  }

  @media only screen and (max-width: 768px) {
    padding: 50px 0rem;
  }
}

.to-none-990{

  @media only screen and (max-width: 990px) {
   display: none !important;
  }
}

.pad10{
  padding: 10px 0;
}

.pad20{
  padding: 20px 0;
}

.pad40{
  padding: 40px 0;
}

.menu_box2:hover .menu_box-icon2{
    background-color: var(--color-primary);
    color: #fff;
    cursor: pointer;
}

.menu_box:hover .menu_box-icon{
  background-color: var(--color-primary);
  color: #fff;
  cursor: pointer;
}

.menu{

  &_zone{
    border: 5px solid #fcfcfc;
    border-radius: 18px;
    box-shadow: 5px 5px 10px #CCCECF;
    float: right;

    &-box{
      font-family: arial;
      font-weight: bold;
      font-size: 30px;
      border: 9px solid #b91425;
      border-radius: 13px;
      padding: 10px 20px 10px 20px;
      background-clip: content-box;
      background-color: #999;
      background: linear-gradient(135deg, #fcfcfc 63%, #eaeaea 100%);
      text-transform: uppercase;
    }
  }

  &_header{

    &-title{
      color: #1d2025;
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      font-size: 34px;
      text-align: center;

      &::after{
        content: '';
        border: .10px solid #a5005d;
        width: 40px;
        display: block;
        position: relative;
        left: 48%;
        top: 13px;
        z-index: 1000;
      }
    }

    &-title2{
      font-family: 'Montserrat', sans-serif;
      font-size: 36px;
      color: #a5005d;
      font-weight: 500;
      line-height: 1.1;
    }

    &-title3{
      font-family: 'Montserrat', sans-serif;
      font-size: 30px;
      color: #222;
      font-weight: 500;
      line-height: 1.1;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    &-title4{
      font-family: 'Montserrat', sans-serif;
      font-size: 21px;
      color: #a5005d;
      font-weight: 500;
      line-height: 1.1;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    &-title5{
      font-family: 'Montserrat', sans-serif;
      font-size: 20px;
      color: #222;
      font-weight: 500;
      line-height: 1.1;
      margin-top: 20px;
      margin-bottom: 10px;
      text-align: center;
    }

    &-title6{
      font-family: 'Montserrat', sans-serif;
      font-size: 24px;
      color: #333;
      font-weight: 500;
      line-height: 1.1;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    &-title7{
      font-family: 'Montserrat', sans-serif;
      font-size: 18px;
      color: #333;
      font-weight: 500;
      line-height: 1.1;
      margin-top: 20px;
      margin-bottom: 10px;
      text-align: center;
    }

    &-image{
      margin: 0 15px !important;
    }

    &-img{
      width: 100%;
      margin: 0 10px !important;
      display: block;
      max-width: 100%;
      height: auto;
    }

    &-img2{
      width: 100%;
      margin: 0 10px !important;
      display: block;
      max-width: 100%;
      height: 210.5px;
      object-fit: cover;

      @media only screen and (max-width: 768px) {
        height: auto;
      }
    }


    &-span{
      display: flex;
      position: relative;

      // &::after{
      //   content: '';
      //   border: 10px solid #d6dbe2;
      //   width: 110px;
      //   display: block;
      //   position: absolute;
      //   top: 1.3em;
      //   left: 0;
      // }
    }

    &-p{
      font-family: 'Montserrat', sans-serif;
      color: #8b8e93;
      font-size: 14px;
      font-weight: 300;
      text-align: center;
    }

    &-link{
      font-family: 'Montserrat', sans-serif;
      color: var(--color-primary) !important;
    }
  }


  &_image{
    @media only screen and (max-width: 768px) {
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto;
    }

    &-img{
      width: 100%;
      max-width: 100%;
      height: auto;
      object-fit: cover;
    }

    &-img2{
      display: flex;
      justify-content: center;
      border-radius: 15px;
      max-width: 500px;
      margin: auto;
      object-fit: cover;
      margin: 0 auto;
    }

    &-img3{
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
      margin: 0 auto;
    }

    &-img4{
      width: auto;
      height: 100%;
      object-fit: cover;
      height:  250px;

      @media only screen and (max-width: 990px) {
        display: flex;
        justify-content: center;
        margin: 0 auto;
      }
    }
  }

  &_content{
    margin-bottom: 50px;
    @media only screen and (max-width: 768px) {
      margin-top: 20px;
   }

   &-ul2{

   }

   &-ul{

    &--li{
      font-family: "Montserrat", sans-serif;
      color: #333;
      font-size: 14px;
      font-weight: 400;
    }

    &--link{
      font-family: "Montserrat", sans-serif;
      color: #a5005d;
      font-size: 14px;
      font-weight: 400;

      &:hover{
        color: #a5005d;
      }
    }
   }

   &-link{
    font-family: "Montserrat", sans-serif;
    color: #fff;
    background-color: #a5005d;
    border-color: #a5005d;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 6px;
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: .10px solid transparent;
    white-space: nowrap;

    @media only screen and (max-width: 1300px) {
      white-space: normal !important;
   }

    &:hover{
      color: #fff;
      text-decoration: none;
      cursor: pointer;
    }
   }

   &-header3{
    font-family: "Montserrat", sans-serif;
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
    font-size: 21px;
    line-height: inherit;
    color: #333;
    border: 0;
    border-bottom: .10px solid #e5e5e5;
    padding-bottom: 10px;
   }

    &-form{

      &--title{
        color: #fff;
        font-family: 'Montserrat', sans-serif;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        padding-bottom: 20px;
      }

      &--input{
        font-family: 'Montserrat', sans-serif;
        display: block;
        width: 100%;
        height: 45px !important;
        padding: 6px 12px;
        font-size: 16px;
        line-height: 1.42857143;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: none;
        border-radius: 2px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
      }

      &--button{
        font-size: 14px;
        font-weight: 400;
        color: #a5005d;
        font-family: 'Montserrat', sans-serif;
        padding: 10px 20px;
        background-color: #fff;
        border-radius: 20px;
        border: none;
        font-weight: 700;


  @media only screen and (max-width: 990px) {
    margin: 10px auto !important;
    display: flex;
    justify-content: center;
}

        &:hover{
          opacity: 0.8;
          color: #a5005d;
          text-decoration: none;
          transition: .2s all ease-out;
          cursor: pointer;
        }
      }
    }

    &-ul3{
      padding-left: 40px;

      @media only screen and (max-width: 400px) {
        padding-left: 5px;
       }
    }

    &-ul4{
      padding-left: 40px;

      @media only screen and (max-width: 768px) {
        padding-left: 0;
       }
    }

    &-ul{
      list-style: none;
      padding-left: 50px;

      @media only screen and (max-width: 768px) {
        padding-left: 10px;
       }
    }

    &-ul2{
      list-style: none;
      padding-left: 27px;

      @media only screen and (max-width: 768px) {
        padding-left: 10px;
       }
    }

    &-title{
      font-family: "Montserrat", sans-serif;
      color: #1d2025;
      font-size: 34px;
      font-weight: 500;
      line-height: 1.1;
      position: relative;

      &::after{
        content: '';
        border: 0.5px solid #a5005d;
        width: 40px;
        display: block;
        position: relative;
        top: 12px;
        z-index: 1
      }

      &-span{
        display: inline-block;
        position: relative;

        &::after{
          content: '';
          border: 10px solid #d6dbe2;
          width: 110px;
          display: block;
          position: absolute;
          top: 50px;
          left: 0;
        }
      }
    }

    &-p{
      font-family: "Montserrat", sans-serif;
      color: #8b8e93;
      font-weight: 300;
      margin-bottom: 0;
      font-size: 14px;
      line-height: 26px;
      padding-bottom: 10px;
    }

    &-li2{
      padding-bottom: 0;
      position: relative;
      list-style: none;
      font-family: "Montserrat", sans-serif;
      color: #8f8f8f;
      line-height: 1.7;
      font-weight: 400;
      font-size: 14px;
    }

    &-li{
      padding-bottom: 0;
      position: relative;
      list-style: none;
      font-family: "Montserrat", sans-serif;
      color: #8f8f8f;
      line-height: 1.7;
      font-weight: 400;
      font-size: 14px;

      &::before{
        position: absolute;
        font-family: "Font Awesome 5 Free";
        display: inline-block;
        top: 0;
        left: -24px;
        z-index: 0;
        font-size: 14px;
        color: #000;
        content: "\f058";
        text-align: center;

        @media only screen and (max-width: 768px) {
          left: -17px;
       }
      }

      &--link{
        padding-bottom: 5px;
        position: relative;
        list-style: none;
        font-family: "Montserrat", sans-serif;
        color: #000;
        line-height: 1.7;
        font-weight: 400;
        font-size: 15px;
  
        &::before{
          position: absolute;
          font-family: "Font Awesome 5 Free";
          display: inline-block;
          top: 0;
          left: -24px;
          z-index: 0;
          font-size: 14px;
          color: #000;
          content: "\f058";
          text-align: center;

          @media only screen and (max-width: 768px) {
            left: -17px;
         }
        }

        &:hover{
          transition: .2s all ease-in-out;
          color: #696c7f !important;
          text-decoration: none;
          cursor: pointer;
        }
      }

      &--link3{
        padding-bottom: 5px;
        position: relative;
        list-style: none;
        font-family: "Montserrat", sans-serif;
        color: #000;
        line-height: 1.7;
        font-weight: 400;
        font-size: 14px;
  
        &::before{
          position: absolute;
          font-family: "Font Awesome 5 Free";
          display: inline-block;
          top: 0;
          left: -24px;
          z-index: 0;
          font-size: 14px;
          color: #000;
          content: "\f058";
          text-align: center;

          @media only screen and (max-width: 768px) {
            left: -17px;
         }
        }

        &:hover{
          transition: .2s all ease-in-out;
          color: #696c7f !important;
          text-decoration: none;
          cursor: pointer;
        }
      }

      &--link2{
        padding-bottom: 5px;
        position: relative;
        list-style: none;
        font-family: "Montserrat", sans-serif;
        color: #000;
        line-height: 1.7;
        font-weight: 400;
        font-size: 15px;


        &:hover{
          transition: .2s all ease-in-out;
          color: #696c7f !important;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }

    &-link{

      @media only screen and (max-width: 990px) {
        display: flex;
        justify-content: center;
        margin-top: 10px;
      }

      &--a{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        font-family: "Montserrat", sans-serif;
        border-radius: 5px 5px 5px 5px;
        background: var(--color-primary);
        border: .20px none transparent;
        box-shadow: 0px 0px 0px -7px rgba(0, 0, 0, 0);
        padding: 15px 25px  !important;
        white-space: nowrap;
        color: #fff !important;
        text-transform: none !important;
        border-radius: .30px !important;
  
        @media only screen and (max-width: 1200px) {
          margin-right: 0 !important;
        }
  
        &:hover {
          text-decoration: none;
          background: var(--color-secondary) !important;
          color: #fff !important;
          transition: all .5s;
          cursor: pointer;
        }
      }
    }
  }

  &_counters{
    padding: 30px 0;

    &-title{
      color: #fff;
      text-align: center;
      font-family: "Montserrat", sans-serif;
      font-size:  55px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.7;
      letter-spacing: -1.2px;
      white-space: nowrap
    }

    &-p{
      color: #fff;
      text-align: center;
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px; /* 133.333% */
    }
  }

  &_box2{
    border: .10px dashed rgba(0, 0, 0, .2);
    padding: 0 35px 45px;
    text-align: center;
    transition: all .3s ease;
    height: 100%;
    margin: 0 10px !important;

    &:hover{
      box-shadow: 0 6px 20px rgba(0, 0, 0, .1);
      border: .10px dashed transparent;
    }
  }

  &_box3{
    background-color: #fff;
    padding: 0 20px 30px;
    border-radius: .20px;
    transition: all .3s ease;
    text-align: center;

    &:hover{
      box-shadow: 0 6px 20px rgba(0, 0, 0, .1);
    }

    &-title{
      font-family: "Montserrat", sans-serif;
      color: #222;
      font-weight: 500;
      font-size: 50px;
      margin-top: 0px;
    }
  }

  &_box{

    &-icon{
      border: .10px dashed #ccc;
      width: 130px;
      height: 130px;
      border-radius: 50%;
      margin-bottom: 20px;
      display: inline-block;
      line-height: 128px;
      transition: all .3s ease;
      text-align: center;
      font-size: 30px;
      color: var(--color-primary);

      &:hover{
        background-color: var(--color-primary);
        color: #fff;
        cursor: pointer;
      }
    }

    &-icon2{
      border: .10px dashed #ccc;
      width: 130px;
      height: 130px;
      border-radius: 50%;
      margin-bottom: 20px;
      display: inline-block;
      line-height: 128px;
      transition: all .3s ease;
      text-align: center;
      font-size: 30px;
      color: var(--color-primary);
      margin-top: -60px;
      background-color: #fff;

      @media only screen and (max-width: 990px) {
        margin-top: 10px;
       }

      &:hover{
        background-color: var(--color-primary);
        color: #fff;
        cursor: pointer;
      }
    }

    &-icon3{
      width: 50px;
      height: 50px;
      background: #a5005d;
      border: 0 solid transparent;
      color: #fff;
      line-height: 48px;
      border-radius: 50%;
      text-align: center;
      margin: auto;
      display: inline-block;
      position: relative;
      top: -20px;
      font-size: 20px;
    }

    &-title{
      font-family: "Montserrat", sans-serif;
      font-size: 16px;
      line-height: 1;
      margin-top: 20px;
      margin-bottom: 20px;
      color: #222;
    }

    &-p{
      font-family: 'Montserrat', sans-serif;
      color: #8b8e93;
      font-size: 14px;
      font-weight: 300;
      line-height: 1.7 !important;
      margin-top: 15px;
    }
  }
}

.f-right{
  float: right;

  @media only screen and (max-width: 768px) {
    float: none;
  }
}

.to-flex-center{

  @media only screen and (max-width: 990px) {
      display: flex;
      justify-content: center;
  }
}

.color-prim{
  color: var(--color-primary) !important;
}

.w-auto{
  width: auto !important;
}

.color-bla{
  color: #333 !important;
}

.menu_content-span::after {
  content: '';
  border: .10px solid #d6dbe2;
  width: 90px;
  display: block;
  position: absolute;
  top: 50px;
  left: 30px;
}

.menu_header-span::after {
  content: '';
  border: 0.5px solid #d6dbe2;
  width: 110px;
  display: block;
  margin: auto;
  position: absolute;
  top: 4px;
  left: 0;
  right: 0;
  margin-top: 0;
}



.m-l-1{
  margin: 0 7px !important;

  @media only screen and (max-width: 990px) {
      margin: 0 !important;
  }
}

.to-100-per-990{

  @media only screen and (max-width: 990px) {
    max-width: 95% !important;
  }
}

.to-100-per-768{

  @media only screen and (max-width: 768px) {
    max-width: 100% !important;
  }
}

blockquote {
  font-family: "Montserrat", sans-serif;
  color: #333 !important;
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 18px;
  border-left: 5px solid #eee;
}


input::placeholder {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #8b8e93 !important;
}

textarea::placeholder {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #8b8e93 !important;
}

.c-select{
  position: relative !important;
  display: inline-block !important;
}

.c-select:after {
  content: '\25BC'; /* Unicode for chevron-down icon */
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Make sure the icon doesn't interfere with clicking */
  font-size: 16px !important; /* Adjust the size of the icon */
  color: #333; /* Color of the icon */
}

.c-select option:first-child {
  font-family: "Montserrat", sans-serif;
  font-size: 14px !important; 
}

.br{
  border-radius: 10px;
}

.after-line{
  position: relative;

  &::after{
    content: '';
    display: block;
    width: 30%;
    height: 1px;
    background: #0a103c;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

b, strong {
  font-weight: 700 !important;
}

.stripe{
  background: #12394b;

  &_content{
    padding: 40px 0;

    &-title{
      font-family: "Montserrat", sans-serif;
      color: #000;
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 20px;
      text-align: center;
      letter-spacing: .01em;

      @media only screen and (max-width: 480px) {
        font-size: 20px;
      }
    }
  }
}



.accordion {
  .accordion-item {
    position: relative;
    background: #333 !important;
    color: #fff;
    margin-bottom: 20px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    border: 5px solid #da4;
    
    .accordion-title {
      position: relative;
      color: #fff !important;
      font-size: 18px;
      font-weight: 400;
      text-align: left;
      white-space: nowrap;
      padding: 10px 15px;

      @media only screen and (max-width: 1200px) {
        white-space: normal !important;
        display: flex;
     }

      // &::before{
      //   content: '\f068';
      //   color: #333;
      //   background-color: #fff;
      //   border-radius: 50%;
      //   font-style: normal;
      //   font-weight: 500;
      //   width: 25px;
      //   height: 25px;
      //   font-size: 15px;
      //   line-height: 1.44;
      //   border-radius: 50%;
      //   font-family: "Font Awesome 5 Free";
      //   right: 2.4rem;
      //   position: absolute;
      //   top: 10px;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   text-align: center;
      // }


      &[aria-expanded="true"]::before {
        content: '\f068';  // Change to minus icon when expanded
      }

      .card-arrow {
        content: '\f068';
        color: #333;
        background-color: #fff;
        border-radius: 50%;
        font-style: normal;
        font-weight: 500;
        width: 25px;
        height: 25px;
        font-size: 15px;
        line-height: 1.44;
        border-radius: 50%;
        font-family: "Font Awesome 5 Free";
        right: 24px;
        position: absolute;
        top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;;
        // transform: rotate(45deg);
        transition: transform 0.3s ease;
      }
      
      &[aria-expanded="true"] .card-arrow {
        transform: rotate(-135deg);
      }
    }
    
    .accordion-body {
      background-color: #fff;
      padding: 15px;
      border-top: none;
      padding-top: 20px;
    }
  }
}

.section-gallery {
  padding: 60px 0;

  .section-title {
    font-family: "Montserrat", sans-serif; 
    font-size: 2em;
    margin-bottom: 20px;
  }

  .carousel {
    .item {
      position: relative;
      overflow: hidden;

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: opacity 0.3s ease;
        color: #fff;

        h3 {
          color: #000;
          letter-spacing: .05em;
          font-size: 20px;
          text-transform: uppercase;
        }
      }

      img {
        width: 100%;
        height: auto;
        transition: transform 0.3s ease;
        z-index: 0;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(54, 54, 54, 0.5) !important;
          z-index: -1;
          transition: 0.5s;
        }
      }

      &:hover {
        .overlay {
          opacity: 1;
        }

        img {
          transform: scale(1.1);
        }
      }
    }
  }
}


.testimonial{
  position: relative;
  background-image: url("../img/testimonial-bg.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;


  @media only screen and (max-width: 990px) {
    margin-top: 40px !important;
  }

  &_intro{
    font-family: "Montserrat", sans-serif;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0 5px 20px -6px rgba(0, 0, 0, .4);
    position: relative;
    padding: 70px 20px;
    background: var(--color-primary);
    border-radius: .20px;
    margin-bottom: 15px;

    @media only screen and (max-width: 768px) {
      padding: 40px 10px;
    }
  }

  &_icon{
    width: 120px;
    height: 120px;
    border-radius: inherit;
    background: var(--color-secondary);
    -webkit-box-shadow: 0 5px 23px -6px rgba(0, 0, 0, .4);
    box-shadow: 0 5px 23px -60px rgba(0, 0, 0, .4);
    position: absolute;
    top: -50px;
    left: -40px;
    line-height: 120px;
    text-align: center;
    color: #fff;
    font-size: 30px;

    @media only screen and (max-width: 768px) {
      width: 70px;
      height: 70px;
      font-size: 24px;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
  }

  &_item{

    @media only screen and (max-width: 768px) {
     margin-top: 20px;
    }

    &-icon{
      color: #a5005d;
      font-size: 36px;
      padding-bottom: 30px;
    }

    &-title{
      font-family: "Montserrat", sans-serif;
      font-size: 17px;
      margin-top: 10px;
      margin-bottom: 10px;
      font-weight: 400;
      color: #222;
    }

    &-p{
      font-size: 16px;
      color: #8b8e93;
      font-family: 'Montserrat', sans-serif;
      font-weight: 300;
      line-height: 26px;
      padding-bottom: 20px;
    }
  }

  &_image{
    float: right;

    @media only screen and (max-width: 480px) {
        margin-top: 10px;
     }

    &-img{
     
    }
  }
}

.bg-light-grey{
  background-color: #f5f5f6 !important;
}

.to-end{
  justify-content: end;

  @media only screen and (max-width: 990px) {
    justify-content: center;
 }
}

.service{

  &-box{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 30px 0;
    min-height: 100%;
    transition: all .2s ease;
    z-index: 1;
  }

  &-icon{
    font-size: 3.20px;
    text-align: center;
    color: #000
  }
}

.text-shad{
  text-shadow: 1px 1px 2px black;
}

.bgrey{
  background: #f9f9f9;
}

.item{
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3) !important;
    transition: 0.5s;
  }
}

.color-black{
  color: #000 !important;
  background-color: #000 !important;
}

 footer{
  background-color: #1d2025;
  padding: 30px 0px;
  border: 0px solid #e3e3e3;
  border-radius: 0px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
 }

 footer img {
  margin: auto;

  @media only screen and (max-width: 990px) {
    width: 100%;
 }
}

ul.social {
  text-align: center;
  padding: 0px;
}

ul.social li {
  display: inline-block;
}

ul.social li a {
  font-size: 14px;
  color: #fff;
  padding: 0px 10px;
}

footer hr {
  background-color: rgba(255, 255, 255, .05);
  margin-top: 0;
  height: .10px;
  margin-bottom: 0;
  border-top: .10px solid rgba(0, 0, 0, .1);
}

ul.pages {
  text-align: center;
  margin: 0px;
  padding: 15px 0px;
}

ul.pages li {
  display: inline-block;
}

ul.pages li a {
  font-family: "Montserrat", sans-serif;
  color: #8b8e93;
  font-size: 14px;
  font-weight: 300;
  padding: 0px 5px;
}

footer #bhinternet {
  font-family: "Montserrat", sans-serif;
  padding: 15px 0 0px;
  text-align: center;
  font-size: 13px;
  color: #999;
}

footer #bhinternet a {
  color: #999;
}

.footer{

  &_image{
    display: flex;
    justify-content: center;
  }
}


.footer-contact-wrapper{
  display: flex;
  margin: 10px 0;
}

.text-black{
  font-family: "Montserrat", sans-serif;
  color: #000 !important;
}

.banner{
  position: relative;
  background-image: url("../img/banner.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, .8) 9%, transparent 40%);
    z-index: -1;
    transition: 0.5s;
  }

  &-2{
    position: relative;
    background-image: url("../img/banner2.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
    // margin-bottom: 25px;
  
    // &::before {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   background: linear-gradient(to bottom, rgba(0, 0, 0, .8) 9%, transparent 40%);
    //   z-index: -1;
    //   transition: 0.5s;
    // }
  }


  &-3{
    position: relative;
    background-image: url("../img/slide1.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
    // margin-bottom: 25px;
  
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(0, 0, 0, .8) 9%, transparent 40%);
      z-index: -1;
      transition: 0.5s;
    }
  }

  &-4{
    position: relative;
    background-image: url("../img/slide1.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
    // margin-bottom: 25px;
  
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(0, 0, 0, .6) 9%, transparent 100%);
      z-index: -1;
      transition: 0.5s;
    }
  }


  &-contact{
    position: relative;
    background-image: url("../img/contact-banner.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
    // margin-bottom: 25px;
  
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(0, 0, 0, .8) 9%, transparent 40%);
      z-index: -1;
      transition: 0.5s;
    }
  }

  &_content2{
    padding: 100px 0;
    //background: linear-gradient(to bottom, rgba(0, 0, 0, .9) 9%, transparent 100%);
  }


  &_content{
    padding-top: 220px;
    padding-bottom: 120px;

    &-title{
      font-family: 'Montserrat', sans-serif;
      color: #fff;
      font-weight: 700;
      text-align: center;
      font-size:  55px;
      background-color: rgba(0, 0, 0, 0.6);
      border-radius: 40px;
      margin-top: 50px;

      @media only screen and (max-width: 990px) {
        font-size: 45px;
      }

      @media only screen and (max-width: 768px) {
       font-size: 40px;
      }
    }
  }
}

.to-four-rem{
 font-size: 40px !important;
  color: #000;
  font-weight: 600 !important;
}

.bg-trans{
  background: transparent !important;
}

.empty-star{

}

.avis{

  &_form{

    &-label{
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      color: #333;
      font-weight: 700;
      text-align: right;

      @media only screen and (max-width: 580px) {
        text-align: left;
      }
    }

    &-input{
      display: block !important;
      width: 100% !important;
      height: 34px !important;
      padding: 6px 12px !important;
      font-size: 14px !important;
      line-height: 1.42857143 !important;
      color: #555 !important;
      background-color: #fff !important;
      background-image: none !important;
      border: .10px solid #ccc !important;
      border-radius: .20px !important;
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
      -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
      transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    }
  }

  &_content{

    &-title{
      font-family: 'Montserrat', sans-serif;
      color: #333;
      font-size: 14px;
      font-weight: 400;
      margin: 10px 0;
    }

    &-date{
      font-family: 'Montserrat', sans-serif;
      color: #333;
      font-size: 14px;
    }

    &-p{
      font-family: 'Montserrat', sans-serif;
      color: #333;
      font-size: 14px;
    }
  }

  &_pagination{
    margin-top: 20px !important;

    &-title{
      padding: 9px 13px !important;
      font-size: 14px !important;
      color: #999 !important;
      background-color: #fff !important;
      border-color: #ddd !important;
      cursor: not-allowed;
    }

    &-active{
      padding: 9px 13px !important;
      font-size: 14px !important;
      color: #fff !important;
      background-color: var(--color-primary) !important;
      border-color: #ddd;
      cursor: not-allowed;
    }
  }
}


.f-left{
  float: left !important;
}

.count-wrapper {
  padding: 30px;
  background: #fff;
  border-radius: 5px;
  -webkit-box-shadow: .20px .30px 10px 0 rgba(0, 0, 0, .05);
  box-shadow: .20px .30px 10px 0 rgba(0, 0, 0, .05);
  margin-bottom: 40px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: -5px;
    right: auto;
    bottom: auto;
    left: -5px;
    width: 3.30px;
    height: 3.30px;
    border-left: 0 solid transparent;
    border-right: 3.30px solid transparent;
    border-top: 3.30px solid #da4;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -ms-transition: all .2s;
    transition: all .2s;
    -webkit-transform: translate(-10px, -10px);
    -moz-transform: translate(-10px, -10px);
    -ms-transform: translate(-10px, -10px);
    transform: translate(-10px, -10px);
  }

  &::after {
    content: "";
    position: absolute;
    top: auto;
    right: -5px;
    bottom: -5px;
    left: auto;
    width: 33px;
    height: 33px;
    border-left: 33px solid transparent;
    border-right: 0 solid transparent;
    border-bottom: 33px solid #da4;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -ms-transition: all .2s;
    transition: all .2s;
    -webkit-transform: translate(10px, 10px);
    -moz-transform: translate(10px, 10px);
    -ms-transform: translate(10px, 10px);
    transform: translate(10px, 10px);
  }

  &:hover {
    &::before,
    &::after {
      visibility: visible;
      opacity: 1;
      -webkit-transform: translate(0, 0);
      -moz-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
}


.count{
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  color: #212121;
  font-size: 35px;
}

.sign{
  font-weight: 900;
  color: #212121;
  font-size: 30px;
  font-family: "Montserrat", sans-serif;
}

.chiffre h3 {
  font-size: 16px;
  font-weight: 500;
  color: #da4;
  font-family: "Montserrat", sans-serif;
  margin: 15px 0 0;
}

ul.choix  li:hover {
	cursor: pointer;
	color: #a5005d !important;
}
ul.choix li.active{
	color: #a5005d !important;
	font-weight: 500;
}

li.active{
	color: #a5005d !important;
	font-weight: 500;
}

ul.choix{
	padding: 0px;
}
ul.choix li{
        font-family: "Montserrat", sans-serif;
        list-style: none;
        font-size: 14px;
        font-weight: 400;
        position: relative;
        color: #222;
        padding-left: 0;
        padding: 15px 0;

      &::after {
        content: "";
        position: absolute;
        bottom: 5px;
        left: 0;
        width: 70%;
        border-bottom: 1px solid rgba(139, 142, 147, .25);
      }
}

#contenu1,#contenu2,#contenu3,#contenu4,#contenu5,#contenu6,#contenu7,#contenu8{
	position: absolute;
    left: 15px;
    top: 10px;


    @media only screen and (max-width: 990px) {
      left: 0;
     right: 0;
    }

    // @media only screen and (max-width: 768px) {
    //   position: relative;
    // }
}
#contenu2,#contenu3,#contenu4,#contenu5,#contenu6,#contenu7,#contenu8{
	display: none;
}

.tabs-title{
      font-family: "Montserrat", sans-serif;
      font-size: 30px;
      line-height: 1.1;
      color: #222;
      margin-top: 20px;

      @media only screen and (max-width: 990px) {
        margin-left: 10px;
      }
  
}

.to-margin-high{
  margin-bottom: 10rem;
  margin-top: 2rem;

  @media only screen and (max-width: 768px) {
    margin-bottom: 40rem;
  }
}

.tabs-p{
      font-family: "Montserrat", sans-serif;
      color: #8b8e93;
      font-weight: 300;
      margin-bottom: 0;
      font-size: 14px;
      line-height: 26px;
      margin-top: 15px;
      width: 70%;

      @media only screen and (max-width: 990px) {
        width: 95%;
        margin-left: 10px;
      }
}


// .tabs {
//   &_ul {
//     margin-bottom: 0;
//     padding-left: 0;
//     list-style: none;

//     &-li {
//       padding: 15px 0;
//       position: relative;

//       &::after {
//         content: "";
//         position: absolute;
//         bottom: 0;
//         left: 0;
//         width: 70%;
//         border-bottom: 1px solid rgba(139, 142, 147, .25);
//       }

//       &--link {
//         font-family: "Montserrat", sans-serif;
//         list-style: none;
//         font-size: 14px;
//         font-weight: 400;
//         position: relative;
//         color: #222;
//         padding-left: 0;
//         padding-bottom: 15px;

//         &:hover {
//           transition: all .2s;
//           text-decoration: none;
//           color: var(--color-primary);
//           cursor: pointer;
//         }
//       }

//       &.active .tabs_ul-li--link {
//         color: var(--color-primary);
//       }
//     }
//   }

//   &_content {
//     &-title {
//       font-family: "Montserrat", sans-serif;
//       font-size: 30px;
//       line-height: 1.1;
//       color: #222;
//       margin-top: 20px;
//     }

//     &-p {
//       font-family: "Montserrat", sans-serif;
//       color: #8b8e93;
//       font-weight: 300;
//       margin-bottom: 0;
//       font-size: 14px;
//       line-height: 26px;
//       margin-top: 15px;
//     }
//   }
// }

// .tab-content {
//   .tab-pane {
//     display: none;
//     opacity: 0;

//     &.active {
//       display: block;
//       opacity: 1;
//       animation: fadeOut;
//     }
//   }
// }

// .tabs_ul-li--link .active {
//   background-color: var(--color-primary) !important;
//   color: #fff !important;
// }


@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.fade-in {
  animation: fadeIn .5s forwards;
}

.fade-out {
  animation: fadeOut .5s forwards;
}


// .fade{
//   transition: opacity .25s linear;
// }

.contact {
  padding: 100px 0;
  position: relative;
  margin-left: 40px;
  @media (max-width: 960px) {
    margin-left: 10px;
  }

  &_header {
    &-title {
      font-family: "Montserrat", sans-serif;
      margin-bottom: 40px;
      color: #000;
      line-height: 1.3;
      font-size: 36px;
      font-weight: 500;
    }
  }

  &_content {
    width: 100%;
    max-width: 100%;
    // margin-left: -5rem !important;

    @media only screen and (max-width: 768px) {
      margin-bottom: 30px;
    }

    &-form {
      width: 100%;
      max-width: 100%;
      position: relative;

      &--label {
        font-family: "Montserrat", sans-serif;
        display: block !important;
        color: #424242;
        font-size: 13px;
        display: inline-block;
        margin-bottom: 5px;
        font-weight: 400;
      }

      &--input {
        font-family: "Montserrat", sans-serif;
        font-size: 14px !important;
        padding: 13px 20px !important;
        line-height: 24px;
        background-color: #f5f5f6 !important;
        border-radius: .20px;
        margin-bottom: 10px;
        border: .10px solid #f0f0f0 !important;
        height: 52px;
        box-shadow: none;
        color: #555;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        width: 100%;
      }

      &--textarea {
        display: block;
        max-width: 100% !important;
        border: 1px solid #ccc !important;
        color: #555 !important;
        font-family: "Montserrat", sans-serif;
        font-size: 14px !important;
        line-height: 1.4;
        padding: 18px 20px !important;
        outline: none !important;
        margin-bottom: 20px !important;
        width: 100% !important;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
      }

      &--btn {
        font-size: 14px;
        font-weight: 400;
        color: #fff !important;
        font-family: 'Montserrat', sans-serif;
        padding: 10px 20px !important;
        background-color: #a5005d;
        border-radius: 20px;
        border: none;
        font-weight: 700;
        white-space: nowrap;
        text-transform: none !important;
        float: right;
  
        &:hover {
          opacity: 0.8;
          color: #fff;
          text-decoration: none;
          transition: all .3s;
          cursor: pointer;
        }
      }
      // #contact-form-response {
      //  text-align: center;
      //   background-color: #dff0d8;
      //   padding: 0.5rem;
      //   color: #3c763d;
      //   position: absolute;
      //   top: -14rem;
      //   left: 20%;
      //   width: 80vw;
      //   height: 30px;
      //   border: 1px solid #d6e9c6;
      //   border-radius: 5px;
      //   display: none;
      // }
    }
  }
  &_info {
    display: flex;
    align-items: center;

    &-icon {
      display: inline-block;
      flex-shrink: 0;
      height: 90px;
      width: 90px;
      border-radius: 50%;
      text-align: center;
      line-height: 90px;
      background: var(--color-secondary);
      color: #fff;
      font-size: 30px;
    }

    &-content {
      display: inline-block;
      padding-left: 20px;

      &--title {
        font-family: "Montserrat", sans-serif;
        font-size: 20px;
        font-weight: 700;
        color: #000;
        margin: 15px 0 10px;
        line-height: 1.2;
      }

      &--p {
        font-family: "Montserrat", sans-serif;
        color: #747474;
        font-size: 14px;
        text-align: justify;
        line-height: 1.7;
        font-weight: 400;
        @media (max-width: 500px) {
          font-size: 12px;
        }
      }

      &--link {
        font-weight: 400;
        color: #333;
        @media (max-width: 500px) {
          font-size: 12px;
        }

        &:hover {
          transition: .2s all ease-in-out;
          color: var(--color-primary);
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }
}

.text-grey-black{
  color: #0a103c !important;
}

.form-pseudo{
  display: none !important;
}


.mentions{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 80px 0;
  .text{
    color: #000;
    font-size: 15px;
    line-height: 1.7;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    text-align: left;
    width: 100%;
  }
  .wd{
    width: 100%;
  }
  .pad{
    padding: 10px 0;
  }
  .content-wrap{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    margin-bottom: 40px;
    border: .10px solid var(--color-primary);
    .txt{
    padding: 10px 10px;
    font-size: 16px;
    width: 100%;
    background-color: var(--color-primary);
    color: white;

    // &:hover{
    //   text-decoration: none;
    //   color: var(--color-secondary) !important;
    //   cursor: pointer;
    // }
    }
    .bottom{
      padding: 25px  10px;
      // border: 1.5px solid rgb(218, 214, 214);
      width: 100%;
      #heading{
        font-family: "Montserrat", sans-serif;
        text-align: left;
        font-size: 28px !important;
        margin-bottom: 20px;
      }
    }
  }
}


.link{
  color: var(--color-primary) !important;

  &:hover{
    transition: .3s all ease-in-out;
    color: var(--color-secondary) !important;
    cursor: pointer;
  }
}

.w-t-95{
  width: 95%;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.link2{
  color: #333 !important;

  &:hover{
    text-decoration: none;
    transition: .3s all ease-in-out;
    color: #696c7f !important;
    cursor: pointer;
  }
}

.active-clr {
  color: var(--color-secondary) !important;
}


.page404{

  &_content{
    padding: 20px 0;

    &-title{
      color: #333;
      font-family: "Montserrat", sans-serif;
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
    }

    &-p{
      color: #747474;
      font-family: "Montserrat", sans-serif;
      font-size: 21px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 52.5px */
      letter-spacing: 0.35px;
      margin: 20px 0;
    }

    &-link{
      color: #000;
      font-family: "Montserrat", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 52.5px */
      letter-spacing: 0.35px;

      &:hover{
        transition: .2s all ease-in-out;
        text-decoration: none;
        color: var(--color-secondary);
        cursor: pointer;
      }
    }
  }

}

.error {
  color: red;
  font-size: 14px;
  text-align: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.fixphone {
  position: fixed;
  z-index: 10000 !important;
  bottom: 0;
  right: 5px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  color: #FFFFFF;

  @media only screen and (max-width: 990px) {
    display: none;
  }


  @media (max-width: 400px) {
    bottom: 0 !important;
    right: 0 !important;
  }

  .button {
    font-family: "Montserrat", sans-serif;
    margin-bottom: 20px;
    margin-right: 10px;
    padding: 13px 25px;
    border-radius: 15px;
    color: #fff;
    background-color: var(--color-primary);
    display: inline-block;
    border: unset;
    transition: all 0.5s;

    @media (max-width: 400px) {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 9px;
    }

    &:hover {
      text-decoration: none;
      background: #fff;
      border: 2px solid var(--color-primary);
      color: var(--color-primary);
      cursor: pointer;
    }
  }
}

.underline {
  text-decoration: underline !important;
}


#fixphone {
  font-family: "Montserrat", sans-serif;
  position: fixed;
  bottom: 14%;
  right: 15px;
  background: var(--color-primary);
  border: 2px solid var(--color-primary);
  color: #fff;
  font-size: 18px;
  padding: 5px 10px;
  border-radius: 5px;
  transition: 0.3s;
  text-decoration: none;
  z-index: 10000 !important;

  @media only screen and (max-width: 768px) {
    bottom: 3%;
  }
}

#fixphone:hover {
  background: rgba(0, 0, 0, 0);
  color: var(--color-primary);
  text-decoration: none;
}

.success-message {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: rgb(51, 200, 51);
}

.error-message {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: red;
}

#filters {
  position: relative;
  text-align: center;
  padding: 0;
  margin: 0;
  margin-bottom: 0;
  margin-bottom: 50px;
}

#filters li {
  font-family: 'Montserrat', sans-serif;
  display: inline-block;
  cursor: pointer;
  padding-right: 25px;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #151515;
  transition: .3s all ease-in-out;
}

#filters .filter.active {
  color: var(--color-secondary);
  border-radius: 5px;
}

.portfolio {
  margin-bottom: 30px;
}

#portfoliolist .portfolio {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  display: none;
  float: left;
  overflow: hidden;
  transition: opacity 0.2s ease-in-out;
}

.owl-dots {
  text-align: left !important; /* Center the dots */
  margin-top: 10px !important; /* Add some space above the dots */
}

.owl-dot {
  display: inline-block !important; /* Display dots inline */
  width: 10px !important; /* Width of each dot */
  height: 10px !important; /* Height of each dot */
  margin: 5px !important; /* Space between dots */
  background-color: #ccc !important; /* Default background color */
  border-radius: 50% !important; /* Make dots circular */
  transition: background-color 0.3s ease !important; /* Smooth transition for background color */
}

.owl-dot span {
  display: none !important; /* Hide default span inside dot */
}

.owl-dot.active {
  background-color: var(--color-primary) !important; /* Background color for active dot */
}

.owl-dot:hover {
  background-color: var(--color-primary) !important; /* Background color when dot is hovered */
}

#owl .owl-next{
  display: none !important;
}

#owl .owl-prev{
  display: none !important;
}


.len-chk{
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 80% !important;
}

.to-14{
  font-size: 14px !important;
}

.modal-body {
  position: relative;
  padding: 20px !important;
  font-size: 16px !important;
  font-family: 'Montserrat', sans-serif;
}

.modal-footer {
  justify-content: center !important;
  margin-top: 15px !important;
  padding: 19px 20px 20px !important;
  text-align: center;
  border-top: .10px solid #e5e5e5 !important;
}

.btn-modal{
  background-color: #428bca !important;
  padding: 6px 12px !important;
  font-size: 14px !important;
}

.to-column-1920{

  @media only screen and (min-width: 1920px) {
    flex-direction: column !important;
  }
}

.to-start-1920{
  align-items: center !important;
  // @media only screen and (min-width: 1920px) {
  //   align-items: flex-start !important;
  // }
}

.m-to-3{
  margin-top: .30px !important;

  @media only screen and (min-width: 1600px) {
    margin-top: 0 !important;
  }
}

.to-show-1600 {
  display: none !important;

  @media only screen and (min-width: 1600px) {
    display: flex !important;
    margin-left: -60px !important;
  }

  @media only screen and (min-width: 1920px) {
    flex-direction: column;
    margin-left: 0 !important;
  }
}

.to-hide-1600 {
  display: flex !important;

  @media only screen and (min-width: 1600px) {
    display: none !important;
  }
}


.thumbnail {
  display: block;
  padding: 5px;
  padding-bottom: 15px;
  margin-bottom: 20px;
  line-height: 1.42857143;
  background-color: #fff !important;
  border: .10px solid #ddd;
  border-radius: .20px;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

#news a.thumbnail {
  color: #333;
}

.thumbnail-title{
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  color: #333;
  font-weight: 500;
  line-height: 1.1;
  margin-top: 20px;
  margin-bottom: 10px;
}

.thumbnail-date{
  font-family: 'Montserrat', sans-serif;
  color: #8b8e93;
  font-weight: 300;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 26px;
  padding-bottom: 10px;
}

.thumbail-p{
  font-family: 'Montserrat', sans-serif;
  color: #333;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.7 !important;
  margin-top: 5px;
  margin-bottom: 0 !important;
}

.thumbnail-link2{
  color: #333;

  &:hover{
    color: #333;
    text-decoration: none;
    cursor: pointer;
  }
}

.thumbnail-link{
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #a5005d;
  font-weight: 700;

  &:hover{
    text-decoration: none;
    cursor: pointer;
  }
}

.thumbnail-link2:hover .thumbnail-link{
  color: blue !important;
}

.thumbnail-img{
  width: 100%;
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border: .10px solid #ddd;
  padding: 5px
}




// .to-col-1920{
//   display: flex !important;

//   @media only screen and (min-width: 1920px) {
//     display: none !important;
//   }
// }

// .to-col-1920-{
//   display: none !important;

//   @media only screen and (min-width: 1920px) {
//     display: flex !important;
//     flex-direction: column;
//   }
// }

// Imports
@import "bootstrap";


.dropdown.active {
  a.dropdown-item{
    color: $body-color!important;
    &.active{
      color: $white!important;
    }
  }
}
